<template>
  <VueEditor
    v-model="formData"
    :editor-toolbar="customToolbar"
    :custom-modules="[]"
    :use-custom-image-handler="image"
    :disabled="disabled"
    @image-added="onImageAdded"
    @image-removed="onImageRemoved" />
</template>

<script>
import { Quill } from 'vue2-editor'

const size = Quill.import('attributors/style/size')
size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '30px', '32px', '36px']
Quill.register(size, true)

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    image: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    customModules: [
      {
        alias: 'size',
        module: size
      }
    ]
  }),
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    customToolbar () {
      const tools = [
        [
          {
            size: [false, '10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '30px', '32px', '36px']
          }
        ],
        ['bold', 'italic', 'underline', 'strike'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { list: 'check' }
        ],
        [
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link'],
        [
          {
            color: [
              '#026EAA',
              '#333333',
              '#FFFFFF',
              '#07BAC6',
              '#27AE60',
              '#FFDF7D',
              '#EB5757',
              '#DED6C8'
            ]
          },
          { background: [] }
        ]
      ]

      if (this.image) {
        tools.push(['image'])
      }

      tools.push(['clean'])

      return tools
    }
  },
  methods: {
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onloadend = (f) => {
          resolve({
            file,
            preview: f.target.result,
            mediaUrl: null
          })
        }

        reader.onerror = () => {
          reject(reader)
        }

        reader.readAsDataURL(file)
      })
    },
    async onImageAdded (file, editor, cursor, reset) {
      const result = await this.getBase64(file)

      if (result.preview.startsWith('data:image/')) {
        editor.insertEmbed(cursor, 'image', result.preview)
        this.$emit('image-added', { file, unique: result.preview })
      }

      // else if (result.preview.startsWith('https://storage.googleapis.com/alltotrips-storage')) {}

      reset()
    },
    onImageRemoved (image) {
      if (image.startsWith('data:image/')) {
        this.$emit('image-removed', image)
      }
    }
  }
}
</script>

<style>
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 1.5em !important;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 3em !important;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 4.5em !important;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 6em !important;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 7.5em !important;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 9em !important;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 10.5em !important;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 12em !important;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 13.5em !important;
}
.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1em !important;
}
</style>
